body {
  font-family: "Montserrat" !important;
}

a {
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
}
/** CUSTOM **/
.bg-color-1 {
  background: linear-gradient(90deg, #311B92, #512DA8) !important;
}